import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Grid, Typography, Divider, Link } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Svg, { ICON } from '_components/svg'
import HistoryLog from '_models/history-log'
import { Modal } from '@refera/ui-web'
import { RateServiceModal } from 'src/components/service-order/modals/RateServiceModal'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { NEXT_URL } from '_config/environment'

import useStyles from './styles'
import { Button } from '@mui/material'

const HistoryItem = ({ item, isLastItem, className }) => {
  const styles = useStyles({ isLastItem })
  const [modalOpen, setModalOpen] = useState(false)
  const [rateServiceModalOpen, setRateServiceModalOpen] = useState(false)
  const [selectedRatingId, setSelectedRatingId] = useState(null)
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const arrayOfOptionsSelectedReprovedService = item.optionsSelectedReprovedService
    ? item.optionsSelectedReprovedService.replace(/'/g, '').slice(1, -1).split(', ')
    : []

  return (
    <>
      <Modal
        title={item.moreDetailsModalTitle || 'Motivo da reprovação'}
        className={styles.modal}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        actionsButtons={
          <Grid className={styles.modalButtom}>
            <Button variant="contained" onClick={() => setModalOpen(false)}>
              Ok
            </Button>
          </Grid>
        }
      >
        <RateServiceModal
          key="rateServiceModal"
          open={rateServiceModalOpen}
          onClose={() => setRateServiceModalOpen(false)}
          ratingDetails={selectedRatingId}
        />
        <Grid className={styles.modalContent}>
          {arrayOfOptionsSelectedReprovedService.length > 0 && (
            <>
              <Typography className={styles.titleModal}>Relacionado a</Typography>
              <Typography className={styles.textModal}>
                {arrayOfOptionsSelectedReprovedService
                  .map(option => {
                    if (item?.logType === 'service_order_refused') {
                      return item?.optionsSelectedReprovedService
                    }
                    if (option === 'servicos_executados') {
                      return 'Serviços executados'
                    }
                    if (option === 'nota_fiscal_emitida') {
                      return 'Nota fiscal emitida'
                    }
                    if (option === 'fotos_anexadas') {
                      return 'Fotos anexadas'
                    }
                    return 'Outros motivos'
                  })
                  .join(', ')}
                .
              </Typography>
            </>
          )}
        </Grid>
        <Grid className={styles.modalContent}>
          <Typography className={styles.titleModal}>Descrição</Typography>
          <Typography className={styles.descriptionModal}>{item.reasonsReproveService}</Typography>
        </Grid>
      </Modal>
      <Grid className={classnames(styles.history, className)}>
        <Grid className={styles.icon} style={{ backgroundColor: item?.iconBackgroundColor }}>
          {Object.values(ICON).includes(item?.iconType) && (
            <Svg type={item?.iconType} style={{ color: item?.iconColor }} />
          )}
        </Grid>
        {!isLastItem && <Divider orientation="vertical" className={styles.divider} />}
        <Grid className={styles.content}>
          <Typography className={styles.title}>{item?.title}</Typography>
          <Typography className={styles.description}>{item?.message}</Typography>
          {item?.url && (
            <Link className={styles.description} href={item?.url} color="inherit">
              {' '}
              Ver mais detalhes &gt;{' '}
            </Link>
          )}
          {item.optionsSelectedReprovedService && (
            <Button className={styles.button} onClick={() => setModalOpen(true)}>
              Mais detalhes
            </Button>
          )}
          {item?.logType === 'intermediary_budget_refused' && item?.reasonsReproveService && (
            <Button className={styles.button} onClick={() => setModalOpen(true)}>
              Ver mais detalhes
            </Button>
          )}
          {(item.logType === 'tenant_approved_service' ||
            item.logType === 'tenant_repproved_service') && (
            <Button
              className={styles.button}
              onClick={() => {
                if (item.userActiveProfile === 'refera') {
                  setSelectedRatingId(item.rating)
                  setRateServiceModalOpen(true)
                } else {
                  window.open(
                    `${NEXT_URL}/${serviceOrder?.get('agency')?.get('slug')}/service-rating?token=${
                      serviceOrder?.uuid
                    }&rating=${item.rating}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              }}
            >
              Ver mais detalhes
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  )
}

HistoryItem.propTypes = {
  item: PropTypes.instanceOf(HistoryLog).isRequired,
  isLastItem: PropTypes.bool,
  className: PropTypes.string,
}

HistoryItem.defaultProps = {
  isLastItem: false,
  className: '',
}

export default React.memo(HistoryItem)
